'use strict'

###*
 # @ngdoc service
 # @name mundoUtils.factory:ModelHelpers

 # @description

###
angular
  .module 'mundoUtils'
  .factory 'ModelHelpers', [
    'MundoMap'
    (
      MundoMap
    ) ->
      ModelHelpersBase = {}

      ModelHelpersBase.verifyLocation = (isDirty, model, coordinates) ->
        return new Promise (resolve, reject) ->
          if isDirty == false
            model.locationLongitude = coordinates[0]
            model.locationLatitude = coordinates[1]
            resolve(model)
          else
            MundoMap.getGeocode model.locationDescription
            .then (result) ->
              model.locationLongitude = result.coordinates[0]
              model.locationLatitude = result.coordinates[1]
              resolve(model)

      ModelHelpersBase
  ]
